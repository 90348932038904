let disableEnter = false;

window.addEventListener('load', () => {
    let chatId;
    let messagesData = [];
    let host = decodeURIComponent(window.location.href);
    host = (host.includes('loc') || host.includes('192.168.0.208')) ? 'http://bekey.loc/' : host;

    const api = host + 'api/chat-gpt/question';
    const action = document.querySelector('.chatgpt_action');

    if (action) {
        const modal = document.querySelector('.chatgpt_modal');
        const messagesList = modal.querySelector('.modal_content');
        const modal_field = modal.querySelector('.modal_field');
        const closeButton = modal.querySelector('.modal_close');
        const sendMessage = modal.querySelector('.modal_send');
        const typingMessage = modal.querySelector('.chatgpt_typing');
        const image = action.querySelector('img');
        const dateContainer = modal.querySelector('.modal_day');

        action.addEventListener('click', () => {
            toogleModal();

            if (!modal.classList.contains('open')) {
                return;
            }

            modal_field.focus();
            getDataFromStorage();

            if (!chatId) {
                setDataToStorage();
                getDataFromStorage();
                sendData({ chatId, text: [] });
                return;
            }

            createMessageList(messagesData);
        });

        sendMessage.addEventListener('click', () => sendForm());
        modal_field.addEventListener("keypress", (e) => {
            if (e.key === "Enter" && !disableEnter) {
                e.preventDefault();
                sendForm();
            }
        });
    
        closeButton.addEventListener('click', () => toogleModal());

        async function sendData(data) {
            const formData = createFormData(data);
            toogleMessageTyping();
            sendMessage.disabled = true;
            disableEnter = true;
    
            await fetch(api, {
                method: "POST",
                body: formData
            })
                .then(res => res.json())
                .then(res => {
                    sendMessage.disabled = false;
                    disableEnter = false;

                    const messageString = res.data.attributes.text.replace('Norma: ', '');

                    const message = {
                        type: 'bot',
                        message: messageString,
                        transformedMessage: transformMessage(messageString),
                        time: getCurrentTime()
                    };

                    messagesData.push(message);
                    setDataToStorage(chatId, messagesData);
                    createMessage(message);
                    toogleMessageTyping();
                })
                .catch((e) => {
                    sendMessage.disabled = false;
                    disableEnter = false;
    
                    const message = {
                        type: 'bot',
                        message: 'Sorry! Something went wrong. Please try again later!',
                        transformedMessage: 'Sorry! Something went wrong. Please try again later!',
                        time: getCurrentTime()
                    };
    
                    messagesData.push(message);
                    createMessage(message);
                    setDataToStorage(chatId, messagesData);
                    toogleMessageTyping();
                })
        }
    
        function createMessageList(messages) {
            messages.forEach((item) => createMessage(item));
            scrollToBottom();
        }
    
        function createMessage(message) {
            const messageContainer = createNode('div', 'chatgpt_item');
            const messageEl = createNode('div', 'chatgpt_message');
            const messageText = createNode('p', 'chatgpt_text');
            const messageTime = createNode('div', 'chatgpt_time');
    
            if (message.type === 'bot') {
                const messageImage = addBotIcon();
                messageContainer.appendChild(messageImage);
            }
    
            message.type === 'bot'
                ? messageEl.classList.add('bot_message')
                : messageEl.classList.add('user_message');
    
            messageTime.textContent = message.time;
            messageText.innerHTML = message.transformedMessage;
            messageEl.append(messageText, messageTime);
            messageContainer.append(messageEl);
    
            messagesList.insertBefore(messageContainer, typingMessage);
            scrollToBottom();
        }
    
        function addBotIcon() {
            const messageImage = createNode('div', 'chatgpt_icon');
            const img = createNode('img');
    
            img.src = "/static/images/chatgpt_avatar.svg";
            messageImage.appendChild(img);
    
            return messageImage;
        }
    
        function sendForm() {
            if (!modal_field.value.length) {
                return;
            }
    
            const message = {
                type: 'user',
                message: modal_field.value,
                transformedMessage: modal_field.value,
                time: getCurrentTime()
            }
    
            modal_field.value = '';
            modal_field.focus();
            messagesData.push(message);
    
            setDataToStorage(chatId, messagesData);
            sendMessagesToApi(messagesData);
            createMessage(message);
        }
    
        function toogleModal() {
            modal.classList.toggle('open');
            action.classList.toggle('open');
            action.classList.contains('open')
                ? image.src = '/static/images/gpt_close.svg'
                : image.src = '/static/images/gpt_open.svg';
    
            if (!modal.classList.contains('open')) {
                const list = messagesList.querySelectorAll('.chatgpt_item');
                list.forEach((item) => item.remove());
            }
        }
    
        function setDataToStorage(id = null, messages = []) {
            const date = new Date();

            const data = {
                chatId: id || Math.random().toString(36).substring(2, 12) + '-' + Date.now() + '-' + Math.random().toString(36).substring(2, 12),
                messages: messages,
                date: transformDate(date),
            }
            localStorage.setItem('chat', JSON.stringify(data));
        }
    
        function getDataFromStorage() {
            const data = JSON.parse(localStorage.getItem('chat'));
            if (!data) {
                return;
            }
    
            chatId = data.chatId;
            messagesData = data.messages;
            setDate(data.date);
        }
    
        function toogleMessageTyping() {
            typingMessage.classList.toggle('visible')
        }
    
        function sendMessagesToApi(messages) {
            const messagesArr = messages.length > 10 ? messages.slice(-10) : messages;
    
            let data = {
                chatId,
                text: messagesArr.map(item => item.message)
            }
    
            sendData(data);
        }
    
        function scrollToBottom() {
            if (messagesList.scrollHeight > messagesList.clientHeight) {
                messagesList.scroll({ top: messagesList.scrollHeight, behavior: 'smooth' });
            }
        }
    
        function getCurrentTime() {
            const date = new Date();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const formattedHours = hours > 12 ? hours - 12 : hours;
    
            return (formattedHours < 10 ? '0' + formattedHours : formattedHours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + (hours >= 12 ? 'PM' : 'AM');
        }
    
        function createNode(tag, className = null) {
            const node = document.createElement(tag);
            if (!className) {
                return node;
            }
    
            node.classList.add(className);
            return node;
        }
    
        function createFormData(data) {
            const formData = new FormData();
    
            for (const key in data) {
                if (key === 'text' && data['text'].length) {
                    data[key].forEach(item => {
                        formData.append('text[]', item);
                    })
                } else {
                    formData.append(key, data[key]);
                }
            }
    
            return formData;
        }

        function setDate(savedDate) {
            const currentDate = new Date();
            const formattedCurrentDate = transformDate(currentDate);

            const viewDate = formattedCurrentDate === savedDate ? 'Today' : savedDate;
            dateContainer.textContent = viewDate;

        }

        function transformDate(date) {
            return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}.${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}.${date.getFullYear()}`;
        }

        function transformMessage(message) {
            const reg = /(?:https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/ig;
    
            return reg.test(message) ? createLink(message, reg) : message;
        }

        function createLink(message, regExp) {
            return message.replace(regExp, value => {
                const protocol = /\b(http|https)/;
                const isDot = value.charAt(value.length - 1) === '.';

                let link = protocol.test(value) && !value.includes('@') ? value : 'https://' + value;
                
                if (value.includes('@')) {
                    link = 'mailto:' + value;
                }

                return `<a href="${isDot ? link.slice(0, -1) : link}" target="_blank">${isDot ? value.slice(0, -1) : value}</a>${isDot ? '.' : ''}`;
            })
        }
    }
});