function initMobileHidden() {
    $('.mob-services.main-section').on('click', '.link-more', function(e) {
        if( $(e.target).hasClass('open-dedicated-hidden')) {
            $('.hide-content-box.dedicated-hidden').addClass('visible');
            $('body').css('overflowY', 'hidden');
        }

        if( $(e.target).hasClass('open-startups-hidden')) {
            $('.hide-content-box.startups-hidden').addClass('visible');
            $('body').css('overflowY', 'hidden');
        }

        $('.hide-content-box .close-hide-content').on('click', function()  {
            $('.hide-content-box').removeClass('visible');
            $('body').css('overflowY', 'visible');
        });

        $('html, body').animate({scrollTop : 0}, 800);
    });
 }

if ($(window).width() < 768) {
    initMobileHidden();
 }
 else {
    $('.link-more').on('click', function(e)  {
        $('.services-box .hide-content-box, .mob-services-box .hide-content-box').removeClass('visible');
        $(e.target).parent().find('.hide-content-box').addClass('visible');
    });

    $('.hide-content-box .close-hide-content').on('click', function(e)  {
        $(e.target).parent().removeClass('visible');
    })
 }