document.addEventListener("DOMContentLoaded", () => {
	const acceptModal = document.querySelector('#cookie-popup');
	const cookieExist = !!document.cookie
		.split(';')
		.filter((item) => item.includes('cookie_accept'))
		.length

	if (cookieExist) {
		acceptModal.classList.add('hidden');
		return;
	}

	const acceptCookieBtn = document.querySelector('#cookie-popup-btn');

	acceptCookieBtn.addEventListener('click', () => {
		document.cookie = 'cookie_accept=true; max-age=31536000';
		acceptModal.classList.add('hidden');
	})
});
