var url = document.location.toString();
var $element = $('#' + url.split('#')[1]);
if (url.match('#') && $element.length) {
    $('.careers__btn_apply').hide();
    $element.parent('.panel').find('.careers__accordion-section-title').trigger('click');
    $element.parent('.panel').find('.careers__btn_apply').fadeIn();
}

$('.careers__accordion-section-title').on('click', function(){
    window.location.hash = $(this).attr('href');
});

$(window).on('resize', function () {
    handleCareersAccordion();
});

$('.careers__accordion-section-title').on('click', function (e) {

    if ($(window).width() >= 768) {

        if ($(this).find('.careers__btn_apply').css('display') === 'none') {
            $('.careers__btn_apply').fadeOut();
            $(this).find('.careers__btn_apply').fadeIn();
        }

        if ($(this).closest('.panel').find('.panel-collapse').hasClass('in')) {
            e.stopPropagation();
        }
    }
});

function handleCareersAccordion() {


        if (!$('.panel-collapse.careers__accordion-section-content').hasClass('in')) {
            $('.careers__btn_apply').hide();
            $('.careers__accordion-section-title:first').trigger('click');
        }

}

handleCareersAccordion();

$('.careers [data-target]').on('click', function() {
    $('.popup_careers .vacancyId').remove();
    var id = $(this).attr('id');
    $(".popup_careers").append("<input name='vacancyId' class='vacancyId' type='hidden' value='" + id + "'>");
});

function handleCustomInputFile(e) {
    var curFiles = $('input[type=file]')[0].files;
    var filesExt = ['doc', 'docx', 'pdf'];
    var acceptableExtension = false;
    var acceptableSize = true;
    var parts = $('input[type=file]').val().split('.');
    var firstSpanText = $('.popup__file_careers span:eq(0)').html();
    var label = $('input[type=file]+label')[0];
    var fileName = '';


    filesExt.forEach(function (item) {
        if (parts[parts.length - 1].indexOf(item) !== -1) {
            acceptableExtension = true;
        }
    });

    if (curFiles.length && (+curFiles[0].size > 3000000)) {
        acceptableSize = false;
    }

    function error() {
        $('.attach').addClass('active error');
        $('.popup__file_clip').css('border-color', 'red');
    }

    function success() {
        $('.popup__file_careers span:eq(0)').html(firstSpanText).css('color', 'white');
        fileName = ($('input[type=file]')).val().split('\\').pop();
        $('.attach').addClass('active success').removeClass('error');
        $('.popup__file_clip').css('border-color', 'green');
        $('.popup__file_careers span:eq(0)').html('');
        $('.popup__file_careers span:eq(1)').html(fileName).addClass('attached').css('color', 'white');
    }

    if (curFiles.length === 0 || !acceptableExtension || !acceptableSize) {
        e.preventDefault();
        if ($('.attach').hasClass('active')) {
            $('.attach').removeClass('active success');

            setTimeout(function () {
                error();

                if (!acceptableSize) {
                    $('.popup__file_careers span:eq(0)').html('Max size(3MB)').css('color', 'red');
                    $('.popup__file_careers span:eq(1)').html('').removeClass('attached');
                }

                if (!acceptableExtension) {
                    $('.popup__file_careers span:eq(1)').html('.doc, .pdf').css('color', 'red');
                }

                if (curFiles.length === 0) {
                    $('.popup__file_careers span:eq(0)').html('Uploaded cv is required').css('color', 'red');
                }

            }, 1700);
        } else {
            $('.attach').removeClass('success').addClass('active');
            error();

            if (!acceptableSize) {
                $('.popup__file_careers span:eq(0)').html('Max size(3MB)').css('color', 'red');
                $('.popup__file_careers span:eq(1)').html('').removeClass('attached');
            }

            if (!acceptableExtension) {
                $('.popup__file_careers span:eq(1)').html('.doc, .pdf').css('color', 'red');
            }

            if (!curFiles.length) {
                $('.popup__file_careers span:eq(0)').html('Uploaded cv is required').css('color', 'red');
            }
        }
    } else {
        if ($('.attach').hasClass('active error')) {
            $('.attach').removeClass('active');

            setTimeout(function () {
                success();
            }, 1700);
        } else {
            success();
        }
    }
}

$('.popup__send_careers').on('click', handleCustomInputFile);

$('input[type=file]').on('change', handleCustomInputFile);
