import { generateRecaptchaToken } from '../helpers/recaptcha';

let popupCount = 0;
let windowScrollTop = 0;
let currentWindowScrollTop = 0;
let widthOfWindow = $(window).width();

$(window).on('scroll', function () {
    windowScrollTop = $(window).scrollTop();
});

$('[data-target]').on('click', function () {
    if (!$('.contacts')[0]) {
        if (popupCount) {
            return;
        }

        popupCount += 1;

        let id = $(this).attr('data-target');
        let pageName = $(this).attr('data-pageName');
        let $popup = $('.popup_' + pageName).attr('id', id);

        $popup.fadeIn();

        $popup.parent('.popup__wrapper')
            .siblings('.popup__shadow')
            .attr('data-pageName', pageName)
            .fadeIn();

        $('body').css({
            'overflow-y': 'hidden',
            'position': 'fixed'
        });

        if (widthOfWindow <= '991') {
            currentWindowScrollTop = -windowScrollTop;
            let offsetDiff = -windowScrollTop;
            $('body').css({'margin-top': offsetDiff});
        }
    } else {
        $('.popup__wrapper_contacts').click();
    }
    generateRecaptchaToken();
});

$('.popup__close').on('click', function () {
    if (popupCount) {
        popupCount -= 1;
    }

    if (!$(this).closest('.popup_contacts, .popup_').length) {
        $(this).closest('.popup').fadeOut();

        $('body').removeAttr('style');
    }

    $('.popup__shadow').fadeOut();
});

$('.popup__shadow,.popup_careers .popup__close').on('click', function () {
    if (popupCount) {
        popupCount -= 1;
    }
    let pageName = $(this).attr('data-pageName');
    $('.popup__shadow').fadeOut();
    $('.popup__wrapper_' + pageName).find('.popup').fadeOut();
    $('.popup__file_careers span:eq(1)').removeClass('attached');

    resetPopupFields();

    $('body').removeAttr('style');

    if (widthOfWindow <= '991') {
        let offsetDiff = Math.abs(currentWindowScrollTop);
        $(window).scrollTop(offsetDiff);
    }
});

$('.popup_why-bekey,.popup_why-bekey_startup').on('click', function () {
    $(this).addClass('opened');
});

function resetPopupFields() {
    setTimeout(function () {
        $('form input, form textarea').not('.popup__send').val('');
        $('.attach').removeClass('active error');
        $('.popup__file.popup__file_careers span:eq(0)').html('max size (3MB)').css('color', 'white');
        $('.popup__file.popup__file_careers span:eq(1)').html('.doc, .pdf').removeClass('attached').css('color', 'white');
        generateRecaptchaToken();
    }, 300);
}

function resetDiscussFields() {
    setTimeout(function () {
        $('form input, form textarea').not('.popup__send, .form-input-submit').val('');
        $('form .placeholder-text').css('display', 'block');
        $('.attach').removeClass('active error');
        $('.popup__file.popup__file_careers span:eq(0)').html('max size (3MB)').css('color', 'white');
        $('.popup__file.popup__file_careers span:eq(1)').html('.doc, .pdf').removeClass('attached').css('color', 'white');
        generateRecaptchaToken();
    }, 300);
}

$('.popup').on('submit', async function (e) {
    e.preventDefault();
    await generateRecaptchaToken();

    let self = $(this)[0];

    if (widthOfWindow <= '991') {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
    }

    //DON'T HIDE STATIC POPUPS
    if (!($(this).hasClass('popup_why-bekey') ||
            $(this).hasClass('popup_why-bekey_startup') ||
            $(this).hasClass('popup_contacts') ||
            $(this).hasClass('popup_blog-welcome')
        )) {
        $(this).fadeOut();
    }

    //MOVE DOWN CONTACTS POPUP AFTER SUBMITING;
    if ($(this).hasClass('popup_contacts')) {
        $(this).closest('.popup__wrapper').off('mouseover').removeClass('active');
        $('.popup__file_careers span:eq(1)').removeClass('attached');
    }

    let $popup = $(this).closest('.popup');
    let formData = new FormData();

    if ($(this).find('.input-file')[0]) {
        let inputFile = $(this).find('.input-file')[0].files[0];
        formData.append('popup__file', inputFile);
    }

    let url = $(this).attr('action');

    $popup.find('input, textarea').each(function () {
        let name = $(this).attr('name');
        let value = $(this).val();
        if(name) {
            formData.append(name, value);
        }
    });

    $.ajax({
        url: url,
        processData: false,
        contentType: false,
        method: 'POST',
        data: formData,
        statusCode: {
            500: function () {
                $('.popup_thanx').show();
                $('.popup__shadow').fadeIn();
            }
        }
    }).done(function () {
        $('.popup_thanx').show();
        $('.popup__shadow').fadeIn();
    }).fail(function (data, textstatus) {
        $('.popup__shadow').fadeOut();

    $('.popup_thanx').show()
        .children('.popup__title').html('Sorry')
        .parent('.popup_thanx')
        .children('.popup__text').html('Something went wrong. Please try again.');
    }).always(function () {
        self.reset();
        resetPopupFields();
    })
});

$('.form-discuss').on('submit', async function (e) {
    e.preventDefault();
    await generateRecaptchaToken();

    let self = $(this)[0];

    if (widthOfWindow <= '991') {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
    }

    let $popup = $(this).closest('.form-discuss');
    let formData = new FormData();

    let url = $(this).attr('action');

    $popup.find('input, textarea').each(function () {
        let name = $(this).attr('name');
        let value = $(this).val();

        if(name) {
            formData.append(name, value);
        }
    });

    $.ajax({
        url: url,
        processData: false,
        contentType: false,
        method: 'POST',
        data: formData,
        statusCode: {
            500: function () {
                $('.popup_thanx').show();
                $('.popup__shadow').fadeIn();
            }
        }
    }).done(function () {
        $('.popup_thanx').show();
        $('.popup__shadow').fadeIn();
    }).fail(function (data, textstatus) {
        $('.popup__shadow').fadeOut();

    $('.popup_thanx').show()
        .children('.popup__title').html('Sorry')
        .parent('.popup_thanx')
        .children('.popup__text').html('Something went wrong. Please try again.');
    }).always(function () {
        self.reset();
        resetDiscussFields();
    })
});

$('.agree-wrapper span').on('click', function(e) {
    if ($(e.target).hasClass('checkmark')) {
        $('.agree_checkbox input').attr('checked', !$('.agree_checkbox input').attr('checked'))
    }

    const id = $(e.target).attr('id');
    if (id) {
        window.open(`/${id}`, '_blank');
    }
});