$('.expertise-box').on('mouseover', function() {
    if ($(window).width() > 768) {
        if (!$(this).hasClass('active')) {
            $(this).find('svg animate').each(function(i, item) {
                item.beginElementAt(0);
            });
        }

        $(this).addClass('active');
    }
}).on('mouseleave', function() {
    $(this).removeClass('active');
});
