$('.nav__item').on('click', function () {
    $(this).siblings('.nav__item').removeClass('active');
    $(this).addClass('active');
});

if ($(window).width() < 768) {
    closeMenu();
    $('.menu-clone').on('click', closeMenu);
    $('.burger').on('click', openMenu);
}

function openMenu() {
    $('.menu-clone').fadeIn();
    $('.burger').fadeOut();
    $('.menu').css('display', 'flex')
        .hide()
        .fadeIn();
    $('html, body').css('position', 'fixed');
}

function closeMenu() {
    $('.menu-clone').fadeOut();
    $('.menu').fadeOut();
    $('.burger').fadeIn();
    $('html, body').css('position', 'relative');

}

$('.menu__toggle').on('click', function () {
    $(this).toggleClass('active');
});
