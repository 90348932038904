const services = document.querySelectorAll('.services-box');

services.forEach(item => {
    const video = item.querySelector('.services-box-video');
    const poster = item.querySelector('.services-box-poster');

    item.addEventListener('mouseenter', () => {
        if(video.readyState === 4) {
            poster.style.display = 'none';
            video.play();
        }
    });

    item.addEventListener('mouseleave', () => video.pause());
});
