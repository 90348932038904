export function generateRecaptchaToken() {
    return grecaptcha.execute(reCaptchaSiteKey, { action: 'contact' })
        .then((token) => {
            $('input[name=recaptcha_response]').val(token);

            var recaptchaResponseApply = document.getElementById('recaptchaResponseModalApply');
            if (recaptchaResponseApply) {
                recaptchaResponseApply.value = token;
            }

            const recaptchaResponseSubscription = document.getElementById('recaptchaResponse');
            if(recaptchaResponseSubscription){
                recaptchaResponseSubscription.value = token;
            }

            return token;
        }, error => error);
}