//BLOG BOXES CLICK HANDLING

$(window).on("resize", function (e) {
    blogBoxAnimations();
    hideExtraTagsOnMobile();
});

function blogBoxAnimations() {
    if ($(window).width() >= 768) {
        $(".blog-box").each(function () {
            var maxHeight = 'calc(' + ($(this).find(".blog-box__title").height() + 15) + 'px' + ' + 15%)';
            $(this).find(".blog-box__content").css('max-height', maxHeight)
        })
    } else {
        $(".blog-box__content").not(".blog-box_int").css("max-height", '100%');

        $(".blog-box_int").each(function () {
            var maxHeight = 'calc(' + ($(this).find(".blog-box__title").height() + 15) + 'px' + ' + 15%)';
            $(this).find(".blog-box__content").css('max-height', maxHeight)
        })

    }
}

blogBoxAnimations();
//BLOG BOXES CLICK HANDLING END

//HIDE EXTRA TEXT ON MOBILE
function hideExtraTagsOnMobile() {

    $(".blog-box__content").each(function (index, value) {

        if ($(window).width() < 768) {
            $(this).find(".blog-tag:gt(0)").hide();
        } else {
            $(this).find(".blog-tag:gt(0)").show();
        }

    })
}

//HIDE EXTRA TEXT ON MOBILE END

hideExtraTagsOnMobile();

// SEARCH HANDLING

$(".blog-search__input")
    .on("focus", function () {
        $(this).closest(".blog-search").addClass("focused")
    })
    .on("blur", function () {
        $(this).closest(".blog-search").removeClass("focused");
    });

$(".blog-search .search-input").on("keyup", function (e) {
    var searchRequest = $(this).val();
    if (e.keyCode === 13 && searchRequest) {
        filterBlogBoxesByUserSearch(searchRequest)
    }
});

$(".search-wrapper .search-icon, .close").on("click", function (e) {

    var container = $(this).closest('.search-wrapper');
    if (!container.hasClass('active')) {
        container.addClass('active');
        e.preventDefault();
    } else if (container.hasClass('active')) {
        container.removeClass('active');
        // clear input
        container.find('.search-input').val('');
    }

    var searchRequest = $(".blog-search .search-input").val();

    if (searchRequest) {
        filterBlogBoxesByUserSearch(searchRequest)
    }
});

function filterBlogBoxesByUserSearch(searchRequest) {

    if (searchRequest) {
        document.location.replace($(".blog-search .search-input").data('base_url') + "?search=" + searchRequest);
    }
}

// SEARCH HANDLING END

//BLOG SEARCH NOT FOUND

function ifSearchResultsAreEmpty() {
    if (!$(".blog .blog-box").length) {
        $(".blog .blog-search").append("<p class='blog__not-found'>Sorry, but your search returned no results. Please try another query.</p>")
    }
}

ifSearchResultsAreEmpty();

//BLOG SEARCH NOT FOUND END

(function wrapIframe() {
    const externalVideos = document.querySelectorAll('.blog-int__content iframe[src*="youtube"]');

    externalVideos.forEach((item) => {
        item.style.height = '';
        item.style.width = '';

        if (!item.parentElement.classList.contains('video-wrap')) {
            const wrapper = document.createElement('div');
            wrapper.setAttribute('class', 'video-wrap');
            item.parentNode.insertBefore(wrapper, item);
            wrapper.appendChild(item);
        }
    })
})();

function inputForm() {
    let $inputField = $('.form-input');

    $inputField.focus(function () {
        $(this).next('.placeholder-text').hide();
    });

    $inputField.blur(function () {
        if ($(this).val().trim() === '') {
            $(this).next('.placeholder-text').show();
        }
    });
}

inputForm();