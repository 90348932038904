$("[data-ajax-link]").on("click", function () {
    var $topImage = $(this).closest(".design-box").find(".design-box__image");
    var $self = $(this);

    if ($(this).hasClass("active")) {
        $("[data-ajax-link]").removeClass("active");
        var offsetTop = $(this).closest(".design-box")[0].offsetTop;

        $(this).closest(".design-box").removeClass("active").find(".design-box__data").remove();
        $(this).html("Show more");

        $(".page-content__wrapper, html, body").animate({ scrollTop: offsetTop }, 0);
    }
    else {
        var url = window.location.origin + "/" + $(this).data("ajax-link");
        $(this).closest(".design-box").addClass("active").siblings(".design-box").removeClass("active");
        $("[data-ajax-link]").removeClass("active");
        $(this).addClass("active");
        $.ajax({
            url: url,
        }).done(function (data) {
            $(".design-box__data").remove();
            $topImage.after("<div class='design-box__data'>" + data + "</div>");
            $("[data-ajax-link]").html("Show more");
            $self.html("minimize")

            var offsetTop = $(".design-box.active")[0].offsetTop - 30;
            $(".page-content__wrapper, html, body").animate({ scrollTop: offsetTop }, 400);
        });
    }
});
