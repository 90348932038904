const mobileWidth = $('html, body')
const desktopWidth = $('.page-content__wrapper');
const mobileVar = $(window);

const finalWidth = ($(window).width() < 768) ? mobileWidth : desktopWidth;
const mobileContainer = ($(window).width() < 768) ? mobileVar : desktopWidth;

mobileContainer.on('scroll', function() {
    if ($(this).scrollTop() > 1000) {
        $('.scroll-back-btn').fadeIn();
    } else {
        $('.scroll-back-btn').fadeOut();
    }
});

$('.scroll-back-btn').on('click', function() {
    finalWidth.animate({scrollTop : 0}, 800);
});