$('.faq-item__question').on('click', function (e) {
    const $elem = e.target;
    const $elemParent = $elem.closest('.faq-item');

    if (!$($elemParent).hasClass('showing')) {
      $('.faq-item').children('.faq-item__answer').slideUp();
      $('.faq-item').removeClass('showing');
      $($elemParent).children('.faq-item__answer').slideDown();
      $($elemParent).addClass('showing');
    } else {
      $('.faq-item').children('.faq-item__answer').slideUp();
      $('.faq-item').removeClass('showing');
    }
  });