import { mobileAndTabletcheck } from '../helpers/mobileAndTabletcheck';

document.addEventListener("DOMContentLoaded", function() {
    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

    if ("IntersectionObserver" in window) {
        var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(video) {
                if (video.isIntersecting) {
                    for (var source in video.target.children) {
                        var videoSource = video.target.children[source];
                        if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                            videoSource.src = videoSource.dataset.src;
                        }
                    }

                    video.target.load();
                    video.target.classList.remove("lazy");
                    lazyVideoObserver.unobserve(video.target);
                }
            });
        });

        lazyVideos.forEach(function(lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
        });
    }
});

$(document).ready(function() {
    var wrapper = $('.page-content__wrapper');

    if (wrapper.length === 0) {
        return;
    }

    if (!mobileAndTabletcheck()) {
        wrapper.on('scroll', function() {
            $('video').each(function() {
                var mediaVideo = this;
                if (isElementInViewport(mediaVideo)) {
                    mediaVideo.play();
                } else {
                    mediaVideo.pause();
                }
            })
        });
    }

    var ua = navigator.userAgent.toLowerCase();

    window.isAndroid = ua.indexOf("android") > -1;

    window.isElementInViewport = function(el) {
        var rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    if ($('.video-wrapper .motion__video').length === 0) {
        return;
    }

    $('.video-wrapper .motion__video')
        .on('pause', function() {
            $(this).next().removeClass('playing').addClass('paused');
        })
        .on('play', function() {
            var self = this;
            if (isAndroid) {
                $(self).next().addClass('playing').removeClass('loading');
            } else {
                $(this).next().addClass('loading');
            }
        })
        .on('playing', function() {
            $(this).next().addClass('playing').removeClass('loading');
        });


    if (mobileAndTabletcheck()) {
        if ($('.video-wrapper .video-play').length === 0) {
            return;
        }
        $('.video-wrapper .video-play').on('click', function() {
            if (!$(this).hasClass('playing')) {
                $(this).prev()[0].play();
            } else {
                $(this).prev()[0].pause();
            }
        });
    }
});