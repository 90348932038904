
if ($(window).width() > 768) {
    $('.solution_content').on('scroll', function(e) {
        const parent = $(this);
        const scrollParent = parent.scrollTop();
        const parentHeight = parent.innerHeight();
        const items = $(this).children('.solution_item');

        items.each(function(i) {
            const blockId = $(this).attr('id');

            if ((scrollParent + (parentHeight)) > ($(this)[0].offsetTop - parent[0].offsetTop) && (scrollParent - 10) < ($(this)[0].offsetTop - parent[0].offsetTop)) {
                $('.solution_table span[data-id="' +blockId+'"]').addClass('active');
            } else {
                $('.solution_table span[data-id="' +blockId+'"]').removeClass('active');
            }
        })
    });


    $(".solution_table span").on('click', function() {
        const elemId = $(this).data('id');
        const centerPosition = $(".internal_solution")[0].offsetTop - ($(window).height() - $(".internal_solution").innerHeight()) / 2;

        $(".page-content__wrapper, html, body").animate({ 
          scrollTop: centerPosition || $(".internal_solution")[0].offsetTop 
        }, 500);
        
        $('.solution_content').animate({
            scrollTop: $('#' + elemId)[0].offsetTop - $('.solution_content')[0].offsetTop
        }, 1000);
      });
}