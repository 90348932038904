 const blurItem = $('.wrap_blur');
 const scrollBtnPrev = ('.scroll_btn-prev');
 const scrollBtnNext = ('.scroll_btn-next');

$('.portfolio .portfolio_main_wrap').on('scroll', function() {
    const scrollWidth = $(this)[0].scrollWidth - $(this).outerWidth();
    const scrollInPercent = ($(this).scrollLeft() / scrollWidth) * 100;
    blurItem.css('width', 100 - scrollInPercent + '%');

    $(scrollBtnPrev).css('display', scrollInPercent ? 'flex': 'none');
    $(scrollBtnNext).css('display', scrollInPercent < 99 ? 'flex': 'none');
});

$(scrollBtnNext).hover( 
    function() {
        scrollNext();
    },
    function() {
        stopScroll();
    }
)

$(scrollBtnPrev).hover( 
    function() {
        scrollPrev();
    },
    function() {
        stopScroll();
    }
)

function scrollNext(){
    $('.portfolio .portfolio_main_wrap').stop().animate({scrollLeft:'+=60'}, 'fast', 'linear', scrollNext);
}

function scrollPrev(){
    $('.portfolio .portfolio_main_wrap').stop().animate({scrollLeft:'-=60'}, 'fast', 'linear', scrollPrev);
}

function stopScroll(){
    $('.portfolio .portfolio_main_wrap').stop();
}
